/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"  
import PropTypes from "prop-types"
import Nav from "./nav"  
import Seo from "./seo"
import Footer from "./footer"

const Layout = ({ children }) => {  
  return (
    <>
		<Seo />
		<Nav />
		<main>
			{children}
		</main>
		<Footer />
    </>
  )
}

Layout.propTypes = {  
  children: PropTypes.node.isRequired,
}

export default Layout 
